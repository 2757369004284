export let toastConfig = {
    position: "bottom-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
}

export let toastPromiseConfig =
    {
        pending: 'envoi en cours...',
        success: 'envoyé avec succès !',
        error: 'Erreur !'
    }

export let postToastPromiseConfig =
    {
        pending: 'ajout en cours...',
        success: 'ajouté avec succès !',
        error: 'Erreur !'
    }

export let convertToastPromiseConfig =
    {
        pending: 'conversion en cours...',
        success: 'convertie avec succès !',
        error: 'Erreur !'
    }

export let patchToastPromiseConfig =
    {
        pending: 'modification en cours...',
        success: 'modifié avec succès !',
        error: 'Erreur !'
    }

export let deleleToastPromiseConfig =
    {
        pending: 'suppression en cours...',
        success: 'supprimé avec succès !',
        error: 'Erreur !'
    }