import {Header} from "./components/Header";
import React, {lazy, Suspense, useEffect} from "react";
import {Navigate, useLocation, useNavigate, useRoutes} from "react-router-dom";
import {Footer} from "./components/Footer";
import {useStore} from "./store/store";
import {shallow} from "zustand/shallow";
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {toastConfig} from "./components/ui/Utils";
import {
    APP_404,
    APP_AUTH,
    APP_CONTACT,
    APP_GESTION,
    APP_HOME,
    APP_LEGAL,
    APP_LOGOUT,
    APP_PORTFOLIO,
    APP_PORTFOLIO_PROJET,
    GET,
    GET_SKILLS
} from "./api/Routes";
import {ROLE_ADMIN} from "./Utils/constants";
import {AnimatePresence} from "framer-motion";
import {api_request, handleError} from "./api/Api";
import {cleanAuth} from "./Utils/Date";
import * as Sentry from "@sentry/react";
import {Error} from "./components/Error";

const Home = lazy(() => import('./pages/Home'));
const Portfolio = lazy(() => import('./pages/Portfolio'));
const Contact = lazy(() => import('./pages/Contact'));
const Admin = lazy(() => import('./pages/Admin'));
const PortfolioProjet = lazy(() => import('./pages/PortfolioProjet'));
const Mention = lazy(() => import('./components/pages/Mention'));
const Auth = lazy(() => import('./pages/Auth'));
const Logout = lazy(() => import('./pages/Logout'));

Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
        new Sentry.BrowserTracing({}),
        new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

function App() {
    const navigate = useNavigate()
    const [user, updateUser, updateSkills, clearSkills] = useStore(
        (state) => [state.user, state.updateUser, state.updateSkills, state.clearSkills],
        shallow
    )

    useEffect(() => {
        if (user.token !== "") {
            cleanAuth(user.token) && navigate(APP_LOGOUT)
        }

        api_request(GET_SKILLS, GET).then((response) => {
            const skills = response?.data["hydra:member"]
            const skillsNumber = response?.data["hydra:totalItems"]
            const localSave = localStorage.getItem('store')?.skills?.length
            if (localSave < skillsNumber) {
                clearSkills()
                skills.map((skill) => {
                    updateSkills(skill)
                })
            }
        }).catch(handleError)
    }, []);

    const ProtectedRoute = ({user, children}) => {
        if (!user.isLogged) {
            toast('Accès refusé !', toastConfig);
            return <Navigate to={APP_HOME} replace/>;
        }
        if (user.isLogged && user.token) {
            const isAdmin = user.role?.find(role => role === ROLE_ADMIN)
            if (!isAdmin) {
                toast('Accès refusé !', toastConfig);
                return <Navigate to={APP_HOME} replace/>;
            }
        }
        return children;
    };

    const element = useRoutes([
        {
            path: APP_HOME,
            element: <Home/>
        },
        {
            path: APP_CONTACT,
            element: (
                <Contact/>
            )
        },
        {
            path: APP_PORTFOLIO,
            element: (
                <Portfolio/>
            )
        },
        {
            path: APP_PORTFOLIO_PROJET,
            element: (
                <PortfolioProjet/>
            )
        },
        {
            path: APP_AUTH,
            element: (
                <Auth/>
            )
        },
        {
            path: APP_GESTION,
            element: (
                <ProtectedRoute user={user}><Admin/></ProtectedRoute>
            )
        }, {
            path: APP_LOGOUT,
            element: (
                <Logout user={user} updateUser={updateUser}/>
            )
        }, {
            path: APP_LEGAL,
            element: (
                <Mention></Mention>
            )
        },
        {
            path: APP_404,
            element: (
                <Error></Error>
            )
        }
    ]);

    const location = useLocation();

    function renderBackground() {
        switch (location.pathname) {
            case APP_HOME:
                return "bg-gradientColor"
            default:
                return "bg-dark"
        }
    }


    return (
        <div className={renderBackground() + " w-full relative"}>
            <div className={""}>
                <Header></Header>
                <AnimatePresence mode="wait" initial={false}>
                    <Suspense>
                        {React.cloneElement(element, {key: location.pathname})}
                    </Suspense>
                </AnimatePresence>
                <ToastContainer/>
                <Footer/>
            </div>
        </div>
    );
}

export default App;
