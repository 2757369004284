export const GET = "get"
export const POST = "post"
export const PATCH = "patch"
export const DELETE = "delete"
export const AUTH_ROUTE = "/api/login_check"
export const PROSPECT_METHODS = "/api/prospects"
export const CLIENT_METHODS = "/api/clients"
export const PROJET_METHODS = "/api/projets"
export const IMAGE_FOR_PROJET = "/api/image_for_projet"
export const SKILL_FOR_PROJET = "/api/skill_for_projet"
export const PORJETS_IMAGES = "/api/projet_images"
export const PORJETS_SKILLS = "/api/skills_projets"
export const FAQ_METHOD = "/api/faqs"
export const PROSPECT_TO_CLIENT = "/api/convert-prospect-to-client/"
export const GET_PROJETS = "/api/projets"
export const GET_SKILLS = "/api/skills"
export const GET_STATS_PROSPECT = "/api/stats"
export const GET_STATS_CA = "/api/ca"
export const BASE_ROUTE = "https://api.nicolas-castex.fr"
export const BASE_IMG = "https://api.nicolas-castex.fr/public/img/"
export const BASE_ICON = "https://api.nicolas-castex.fr/public/icon/"

export const APP_HOME = "/"
export const APP_CONTACT = "/contact"
export const APP_LEGAL = "/mention-legal"
export const APP_PORTFOLIO = "/portfolio"
export const APP_PORTFOLIO_PROJET = "/portfolio/:id"
export const APP_GESTION = "/gestion-admin"
export const APP_AUTH = "/gestion-auth"
export const APP_LOGOUT = "/logout"

export const APP_404 = "*"


