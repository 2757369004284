import {Link} from "react-router-dom";
import linkedin from "../assets/icons/linkedin.svg";
import {APP_CONTACT, APP_LEGAL} from "../api/Routes";

export function Footer() {
    return (
        <footer className={"flex flex-col items-center pt-24 pb-6 relative z-10"}>
            <div className={'flex flex-col md:flex-row md:justify-around items-center w-10/12 mx-auto mt-16'}>
                <p className={"text-gray-200 text-xs"}>Copyright © 2023. I NEED IT</p>
                <div className={'flex justify-around gap-5 my-6 md:gap-20'}>
                    <Link to={APP_LEGAL} className={"text-light text-xs sm:text-base"}>MENTION LEGALE</Link>
                    <Link to={APP_CONTACT} className={"text-light text-xs sm:text-base"}>CONTACT</Link>
                </div>
                <div className={"flex justify-center gap-10"}>
                    <Link to={"https://www.linkedin.com/in/nicolas-castex-b207421b0/"}><img
                        className={"cursor-pointer aspect-auto"} src={linkedin} alt=""/></Link>
                </div>
            </div>
        </footer>
    )
}
