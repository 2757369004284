import {useNavigate} from "react-router-dom";
import {useEffect} from "react";

export function Error() {
    const navigate = useNavigate()

    useEffect(() => {
        navigate('/')
    }, []);

    return (
        <></>
    )
}