import logo from '../assets/logo.png'
import burger from '../assets/icons/buger.svg'
import {NavLink} from "react-router-dom";
import React, {useState} from "react";
import {motion} from "framer-motion";
import {useStore} from "../store/store";
import {shallow} from "zustand/shallow";
import jwt from 'jwt-decode'
import {APP_CONTACT, APP_GESTION, APP_HOME, APP_PORTFOLIO} from "../api/Routes";
import {ROLE_ADMIN} from "../Utils/constants";


const activeRoute = "text-primary font-bold border-b-4 border-primary rounded";

const variants = {
    open: {opacity: 1, x: 0},
    closed: {opacity: 0, x: "-100%"},
}

function adminRoute(user, children) {
    if (user.isLogged && user.token) {
        const credential = jwt(user.token);
        const isAdmin = credential.roles.find(role => role === ROLE_ADMIN)
        if (isAdmin) {
            return children
        }
    }
}

export function Header() {
    const [user] = useStore(
        (state) => [state.user],
        shallow
    )
    const [isOpen, setIsOpen] = useState();
    return (
        <header className={"relative pt-[30px] z-10 w-10/12 mx-auto"}>
            <nav className={"relative bg-light w-full h-[70px] rounded-[6px]"}>
                <NavLink to={APP_HOME}><img className={"absolute top-[14px] aspect-auto"} src={logo} alt=""/></NavLink>
                <img onClick={() => setIsOpen(!isOpen)}
                     className={"aspect-auto block xl:hidden absolute top-[14px] right-4 w-10"}
                     src={burger} alt=""/>
                <div className={"hidden xl:block ml-20 2xl:ml-0"}>
                    <ul className={'pt-[22.65px] flex justify-center gap-[76px] items-center'}>
                        <li>
                            <NavLink className={({isActive, isPending}) =>
                                (isPending ? "" : isActive ? activeRoute : "") + " " +
                                "text-xl"
                            } to={APP_HOME}>Accueil</NavLink>
                        </li>
                        <li>
                            <NavLink className={({isActive, isPending}) =>
                                (isPending ? "" : isActive ? activeRoute : "") + " " +
                                "text-xl"
                            } to={APP_PORTFOLIO}>Portfolio</NavLink>
                        </li>
                        <li>
                            <NavLink className={({isActive, isPending}) =>
                                (isPending ? "" : isActive ? activeRoute : "") + " " +
                                "text-xl"
                            } to={APP_CONTACT}>Contact</NavLink>
                        </li>
                        {user.isLogged &&
                            adminRoute(user,
                                <li className={user.isLogged ? "visible" : "hidden"}>
                                    <NavLink className={({isActive, isPending}) =>
                                        (isPending ? "" : isActive ? activeRoute : "") + " " +
                                        "text-xl"
                                    } to={APP_GESTION}>Gestion</NavLink>
                                </li>)
                        }
                    </ul>
                </div>
            </nav>
            <motion.div
                animate={isOpen ? "open" : "closed"}
                variants={variants}
                className={`${!isOpen && "hidden"} mt-5 mx-auto bg-light w-full py-4 rounded-[6px]`}>
                <ul className={'flex flex-col gap-10 items-center'}>
                    <li>
                        <NavLink onClick={() => setIsOpen(!isOpen)} className={({isActive, isPending}) =>
                            (isPending ? "" : isActive ? activeRoute : "") + " " +
                            "text-xl"
                        } to={APP_HOME}>Accueil</NavLink>
                    </li>
                    <li>
                        <NavLink onClick={() => setIsOpen(!isOpen)} className={({isActive, isPending}) =>
                            (isPending ? "" : isActive ? activeRoute : "") + " " +
                            "text-xl"
                        } to={APP_PORTFOLIO}>Portfolio</NavLink>
                    </li>
                    <li>
                        <NavLink onClick={() => setIsOpen(!isOpen)} className={({isActive, isPending}) =>
                            (isPending ? "" : isActive ? activeRoute : "") + " " +
                            "text-xl"
                        } to={APP_CONTACT}>Contact</NavLink>
                    </li>
                    <li className={user.isLogged ? "visible" : "hidden"}>
                        {user.isLogged &&
                            <NavLink onClick={() => setIsOpen(!isOpen)} className={({isActive, isPending}) =>
                                (isPending ? "" : isActive ? activeRoute : "") + " " +
                                "text-xl"
                            } to={APP_GESTION}>gestion Admin</NavLink>
                        }
                    </li>
                </ul>
            </motion.div>
        </header>
    )
}
