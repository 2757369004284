import dayjs from "dayjs";
import jwtDecode from "jwt-decode";

export function displayDate(date, format) {
    return dayjs(date).format(format)
}

export function descDate(dateArray) {
    return dateArray.sort((a, b) => {
        return new Date(b.date) - new Date(a.date)
    })
}

export function cleanAuth(token) {
    if (token) {
        const decoded = jwtDecode(token)
        if (decoded.exp * 1000 < new Date()) {
            window.localStorage.removeItem('store')
            return true
        }
    }
    return false
}