import {createWithEqualityFn} from 'zustand/traditional'
import {shallow} from 'zustand/shallow'
import {persist} from "zustand/middleware";

const intiState = {
    login: {},
    user: {},
    skills: [],
    projets: [],
    prospect: [],
    faqs: [],
    client: [],
    stats: []
}

const store = (set) => ({
    user: intiState.user,
    login: intiState.login,
    prospect: intiState.prospect,
    client: intiState.client,
    projets: intiState.projets,
    faqs: intiState.faqs,
    skills: intiState.skills,
    stats: intiState.stats,
    updateUser: (userData) => set((state) => ({user: {...state.user, ...userData}}), false, 'updating user'),
    updateAuth: (loginData) => set((state) => ({login: {...state.login, ...loginData}}), false, 'updating user auth'),
    updateProspect: (prospectData) => set((state) => ({prospect: [...Array.from(state.prospect), {...prospectData}]}), false, 'updating prospect'),
    updateClient: (clientData) => set((state) => ({client: [...Array.from(state.client), {...clientData}]}), false, 'updating client'),
    updateProjets: (projetData) => {
        set((state) => ({projets: [...Array.from(state.projets), {...projetData}]}), false, 'updating projet')
    },
    updateFaqs: (faqData) => {
        set((state) => ({faqs: [...Array.from(state.faqs), {...faqData}]}), false, 'updating faqs')
    },
    updateSkills: (skillsData) => {
        set((state) => ({skills: [...Array.from(state.skills), {...skillsData}]}), false, 'updating skills')
    },
    updateStats: (projetData) => {
        set((state) => ({stats: {...projetData}}), false, 'updating stats')
    },
    clearProjets: () => {
        set(() => ({projets: []}), false, 'clear projet')
    },
    clearSkills: () => {
        set(() => ({skills: []}), false, 'clear skills')
    },
    clearProspect: () => {
        set(() => ({prospect: []}), false, 'clear projet')
    },
    clearClient: () => {
        set(() => ({client: []}), false, 'clear client')
    },
    clearFaqs: () => {
        set(() => ({faqs: []}), false, 'clear faqs')
    },
})

export const useStore = createWithEqualityFn(persist(store, {
    name: "store",
    partialize: (state) =>
        Object.fromEntries(
            Object.entries(state).filter(([key]) => !['prospect', 'login'].includes(key))
        ),
}), shallow)